import { ReactNode, useMemo } from 'react';
import { useGetUnreadQuery } from 'src/api/getstream-api';
import { InlineIcon } from 'src/components/inline-icon';
import type { AuthContextType } from 'src/contexts/auth/firebase-context';
import { useAuth } from 'src/hooks/use-auth';
import { useChatApi } from 'src/hooks/use-chat-api';
import { paths } from 'src/paths';
import { icons } from 'src/theme/icons';

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
  exact?: boolean;
  badge?: boolean;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  const auth = useAuth<AuthContextType>();
  const chat = useChatApi();
  const chatNotifications = useGetUnreadQuery({ auth: chat.auth });

  return useMemo(() => {
    const userSections: Section[] = [
      {
        items: [
          {
            title: 'Aktuelles',
            path: paths.index,
            icon: <InlineIcon icon="fa6-solid:newspaper" />,
            exact: true
          },
          {
            title: 'Kalender',
            path: paths.calendar,
            icon: <InlineIcon icon="fa6-solid:calendar-days" />
          },
          {
            title: 'Dokumente',
            path: paths.documents,
            icon: <InlineIcon icon="fa6-solid:folder" />
          },
          {
            title: 'Chat',
            path: paths.chat,
            icon: <InlineIcon icon="fa6-solid:comment-dots" />,
            badge: !!chatNotifications.data?.total_unread_count
          },
          {
            title: 'Wiki',
            path: paths.wiki,
            icon: <InlineIcon icon="fa6-solid:book" />
          }
        ]
      }
    ];
    const contentSections: Section[] = [
      {
        subheader: 'Inhalte',
        items: [
          {
            title: 'Neuer Inhalt',
            path: paths.content.create,
            icon: <InlineIcon icon={icons.add} />
          },
          {
            title: 'Entwürfe',
            path: paths.content.drafts,
            icon: <InlineIcon icon="fa6-solid:file-lines" />
          },
          {
            title: 'Geplant',
            path: paths.content.planned,
            icon: <InlineIcon icon="fa6-solid:clock" />
          }
        ]
      }
    ];
    const adminSections: Section[] = [
      {
        subheader: 'Administration',
        items: [
          {
            title: 'Verwaltung',
            path: paths.admin.manage.index,
            icon: <InlineIcon icon="fa6-solid:users" />
          },
          {
            title: 'Einstellungen',
            path: paths.admin.settings.index,
            icon: <InlineIcon icon="fa6-solid:gear" />
          }
        ]
      }
    ];
    const accountSections: Section[] = [
      {
        subheader: 'Einstellungen',
        items: [
          {
            title: 'Mein Betrieb',
            path: paths.company.user,
            icon: <InlineIcon icon="fa6-solid:building" />
          },
          {
            title: 'Mein Profil',
            path: paths.account,
            icon: <InlineIcon icon={icons.settings} />
          }
        ]
      }
    ];

    if (auth.user?.groups?.includes('admin'))
      return [
        ...userSections,
        ...contentSections,
        ...adminSections,
        ...accountSections
      ];
    if (auth.user?.groups?.includes('editor'))
      return [...userSections, ...contentSections, ...accountSections];
    return [...userSections, ...accountSections];
  }, [auth.user?.groups, chatNotifications.data?.total_unread_count]);
};
